import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  Button,
  CheckboxController,
  DateSelectController,
  Icons,
  PhoneController,
  Text,
  TextController,
  Toast,
} from "@pushpress/shared-components";

import { saveUserToken } from "../../../api";
import { extractError } from "../../../utils";
import PageContainer from "../../../components/PageContainer";
import PageTitle from "../../../components/PageTitle";
import { useAppContext } from "../../../contexts/AppContext";
import { useToast } from "../../../components/ToastProvider";
import { useAnalytics } from "../../../components/AnalyticsProvider";
import {
  useCreateProfileMutation,
  useUpdateProfileMutation,
} from "./Authentication-generated.hooks";
import {
  ProfileForm,
  convertToCreateProfileInput,
  convertToUpdateProfileInput,
} from "./Authentication.utils";
import TermsLabel from "../../../components/TermsLabel";

interface ProfileStepProps {
  email?: string;
  userExists: boolean;
  userUuid?: string;
  onCreateAccount: (userUuid: string) => void;
  onUpdateAccount: VoidFunction;
  onBack: VoidFunction;
}

const ProfileStep = (props: ProfileStepProps) => {
  const {
    email,
    userExists,
    userUuid,
    onBack,
    onCreateAccount,
    onUpdateAccount,
  } = props;

  const { t } = useTranslation("common");
  const { setErrorToast } = useToast();
  const analytics = useAnalytics();
  const { clientLocale, clientPhoneCountry, client } = useAppContext();
  const { control, handleSubmit } = useForm<ProfileForm>();

  const handleError = (error: unknown) => {
    analytics.trackEvent("profile.fail", {}, true);
    setErrorToast({ message: extractError(error) });
  };

  const [updateProfile, { loading: updateLoading }] = useUpdateProfileMutation({
    onError: handleError,
  });

  const [createProfile, { loading: createLoading }] = useCreateProfileMutation({
    onError: handleError,
  });

  const handleUpdate = async (values: ProfileForm) => {
    const input = convertToUpdateProfileInput(values, userUuid);
    const result = await updateProfile({
      variables: {
        input,
      },
    });
    if (!result.data) {
      return;
    }
    onUpdateAccount();
    analytics.trackEvent("profile.update", {}, true);
  };

  const handleCreate = async (values: ProfileForm) => {
    const input = convertToCreateProfileInput(values, client.uuid, email!);
    const result = await createProfile({
      variables: {
        input,
      },
    });
    if (!result.data) {
      return;
    }

    // save token on cookies
    const token = result.data.createProfile.accessToken!;
    saveUserToken(token);

    // call on create
    const userUuid = result.data.createProfile.userUuid;
    onCreateAccount(userUuid);
    analytics.trackEvent("profile.create", {}, true);
  };

  const loading = updateLoading || createLoading;
  const handleContinue = userExists ? handleUpdate : handleCreate;

  return (
    <PageContainer.RightPane>
      {!userExists && (
        <Button
          buttonType="text"
          text={t("login.goBack")}
          type="button"
          size="small"
          icon={Icons.ArrowLeft}
          iconLocation="left"
          onClick={onBack}
          className="mb-2"
        />
      )}
      <PageTitle title={t("login.profileTitle")} theme="light" />
      <form onSubmit={handleSubmit(handleContinue)}>
        <Text variant="body-lg" color="wolf-600" className="mb-3">
          {email}
        </Text>
        <Toast
          message={t("login.profileInfo")}
          icon={Icons.Information}
          iconPosition="center"
        />
        <TextController
          label={t("login.firstName")}
          control={control}
          name="firstName"
          required
          className="mt-3"
        />
        <TextController
          label={t("login.lastName")}
          control={control}
          name="lastName"
          required
        />
        <DateSelectController
          label={t("login.dateOfBirth")}
          control={control}
          name="birthday"
          required
          locale={clientLocale}
        />
        <PhoneController
          label={t("login.phone")}
          control={control}
          name="phone"
          required
          international
          countryCallingCodeEditable={false}
          defaultCountry={clientPhoneCountry as any}
          className="mb-3"
        />
        <CheckboxController
          data-cy="privacy-check"
          name="privacyConsent"
          control={control}
          defaultValue={false}
          className="mt-4"
          required
          // @ts-expect-error TODO: fix this
          label={<TermsLabel />}
        />
        <CheckboxController
          name="marketingConsent"
          control={control}
          label={t("createAccount.marketingConsent", {
            gymName: client.company,
          })}
          className="mt-3"
        />
        <Button
          fullWidth
          buttonType="primary"
          size="large"
          text={t("login.createAccount")}
          iconLocation="right"
          icon={Icons.ArrowRight}
          type="submit"
          className="mt-4"
          loading={loading}
        />
      </form>
    </PageContainer.RightPane>
  );
};

export default ProfileStep;
